import React from 'react';
import ReactDOM from 'react-dom';
import App from './AppRouter.jsx';

import { Provider } from "react-redux"
import { storeConfig } from './Redux/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import 'react-phone-input-2/lib/style.css'


const store = storeConfig()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

