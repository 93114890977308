import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import update from "immutability-helper";

import "./SuccessPayment.scss";
const jwt = require("jsonwebtoken");

const ENCYPTION_KEY = process.env.REACT_APP_ENCYPTION_KEY;

const SuccessPaid = () => {
  const [userData, setUserData] = useState({
    courseName: "",
    amount: "",
    currencySymbol: "",
  });

  useEffect(() => {
    let data = localStorage.getItem("enrollpaymentUser");
    let decoded = jwt.verify(data, ENCYPTION_KEY);
    const updatedUserData = update(userData, {
      courseName: { $set: decoded.courseName },
      amount: { $set: decoded.amount },
      parentName: { $set: decoded.parentName },
      currencySymbol: { $set: decoded.currencySymbol },
    });

    setUserData(updatedUserData);
  });

  return (
    <div className="text-center mx-auto">
      <Icon name="check circle" color="green" className="mt-5" size="huge" />
      <div className="mx-auto">
        <div className="paddingContent">
          <h2>Hello {userData.parentName}!</h2>
          <h3 style={{ maxWidth: "800px" }} className="text-center mx-auto">
            Your token amount payment of the sum{" "}
            <span className="font-weight-bold text-muted">
              {userData.currencySymbol}
              {userData.amount}
            </span>{" "}
            and course{" "}
            <span className="font-weight-bold text-muted">{userData.courseName} </span>is
            already done and you have been successfully registered with us. Now sit back
            and relax until the commencement of your child's classes for the opted course.
          </h3>
          <h4>
            <div>Spread the word! Invite friends & family by sharing this link:</div>
            <a target="_blank" href="https://www.codeyoung.com">
              www.codeyoung.com
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SuccessPaid;
