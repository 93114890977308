import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { exampleReducer } from './Reducers/exampleReducer'

//reducers imports


const reducers = combineReducers({
    exampleReducer: exampleReducer
})

let middleware = [thunk]

if (process.env.NODE_ENV !== "production") {
    middleware = [...middleware, logger];
}

const middlewareEnhancer = applyMiddleware(...middleware);

export const storeConfig = () => {
    return createStore(reducers, undefined, middlewareEnhancer)
}