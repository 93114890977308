import React, { useState, useEffect } from "react";
import { Form, Button, Container, Col } from "react-bootstrap";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import update from "immutability-helper";
import Loader from "../Loader/Loader";
import { BeatLoader } from "react-spinners";
import beatLoaderStyling from "../../shared-styling/beatLoaderStyling";

import useWindowDimensions from "../../windowDimensions";
const jwt = require("jsonwebtoken");

const BASE_URL = process.env.REACT_APP_ENROLL_PAYMENT_URL;
const ENCYPTION_KEY = process.env.REACT_APP_ENCYPTION_KEY;

const NewUser = () => {
  const [userData, setUserData] = useState({
    parentId: "",
    studentId: "",
    priceId: "",
    tokenPaymentLink: "",
    courseName: "",
    amount: 0,
    finalAmount: 0,
    currencySymbol: "",
    parentName: "",
  });
  const [discountCoupon, setDiscountCoupon] = useState({
    error: "",
    coupon: "",
    status: false,
    discountAmount: 0,
    loader: false,
  });
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    let data = localStorage.getItem("enrollpaymentUser");
    let decoded = jwt.verify(data, ENCYPTION_KEY);
    // setUserData({
    //   parentId: decoded.parentId,
    //   studentId: decoded.studentId,
    //   priceId: decoded.priceId,
    //   parentName: decoded.parentName,
    // });
    Axios.post(`${BASE_URL}/getPriceDetails`, {
      priceId: decoded.priceId,
    }).then((res) => {
      // const encryptedObj = jwt.sign(
      //   {
      //     ...decoded,
      //     courseName: res.data.courseName,
      //     amount: res.data.amount,
      //     currencySymbol: res.data.currencySymbol,
      //     paymentLink: res.data.tokenPaymentLink,
      //     newUser: true,
      //   },
      //   ENCYPTION_KEY,
      // );
      // localStorage.setItem("enrollpaymentUser", encryptedObj);
      const updatedUserData = update(userData, {
        courseName: { $set: res.data.courseName },
        amount: { $set: res.data.amount },
        finalAmount: { $set: res.data.amount },
        currencySymbol: { $set: res.data.currencySymbol },
        parentId: { $set: decoded.parentId },
        studentId: { $set: decoded.studentId },
        priceId: { $set: decoded.priceId },
        parentName: { $set: decoded.parentName },
      });
      setUserData(updatedUserData);
      setLoading(false);
    });
  }, []);

  const handlePayment = () => {
    setSubmitLoader(true);
    let data = localStorage.getItem("enrollpaymentUser");
    let decoded = jwt.verify(data, ENCYPTION_KEY);
    Axios.post(`${BASE_URL}/enroll/student`, {
      studentId: userData.studentId,
      parentId: userData.parentId,
      discountAmount: Math.ceil(discountCoupon.discountAmount),
      priceId: userData.priceId,
    })
      .then((res) => {
        setSubmitLoader(false);
        const encryptedObj = jwt.sign(
          {
            ...decoded,
            orderId: res.data.orderId,
            courseName: res.data.courseName,
            amount: userData.finalAmount,
            currencySymbol: res.data.currencySymbol,
            paymentLink: res.data.tokenPaymentLink,
            newUser: true,
          },
          ENCYPTION_KEY,
        );
        localStorage.setItem("enrollpaymentUser", encryptedObj);
        window.open(res.data.tokenPaymentLink, "_blank");
        history.push("/desired-batch");
      })
      .catch((e) => {
        setSubmitLoader(true);
        console.log("error", e);
      });
  };

  const handleDiscountCode = () => {
    setDiscountCoupon({
      ...discountCoupon,
      loader: true,
    });
    Axios.post(`${BASE_URL}/availCouponCode`, {
      promoCodeId: discountCoupon.coupon,
      courseAmount: userData.amount,
      priceId: userData.priceId,
    })
      .then((res) => {
        setUserData({
          ...userData,
          finalAmount: Math.ceil(userData.amount - res.data.discountAmount),
        });
        setDiscountCoupon({
          ...discountCoupon,
          discountAmount: Math.ceil(res.data.discountAmount),
          status: true,
          loader: false,
        });
      })
      .catch((e) => {
        setDiscountCoupon({
          ...discountCoupon,
          loader: false,
          error: e.response.data.msg,
        });
      });
  };

  const handleCancelDiscount = () => {
    setDiscountCoupon({
      ...discountCoupon,
      discountAmount: 0,
      status: false,
      coupon: "",
    });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <h2 className="my-2">Hi {userData.parentName},</h2>
          <h4>
            Here are your payment details for the selected course{" "}
            <span className="font-weight-bold text-muted">{userData.courseName}</span>.
            {/*  ! This claim is disputed */}
            Kindly enter the token amount to make the payment.
          </h4>
          <Container>
            <div className="row my-3">
              <h3 className="col-6 col-lg-3 font-weight-bold mb-0">Total Amount:</h3>
              <h3 className="col-6 col-lg-2 mb-0 mt-0">
                {" "}
                {userData.currencySymbol} {userData.amount}
              </h3>
            </div>
            {discountCoupon.status && (
              <div>
                <div className="row my-3">
                  <h3 className="col-6 col-lg-3 font-weight-bold mb-0">Discount:</h3>
                  <h3 className="col-6 col-lg-2 mb-0 mt-0">
                    - {userData.currencySymbol} {discountCoupon.discountAmount}
                  </h3>
                </div>
                <div className="row my-3">
                  {/* <h3 className="col-6 col-lg-3 font-weight-bold mb-0">Discount:</h3> */}
                  <h4 className="col-6 offset-6 offset-lg-3 col-lg-4 mb-0 mt-0 text-muted">
                    Code{" "}
                    <span className="font-weight-bold" style={{ color: "black" }}>
                      {discountCoupon.coupon}
                    </span>{" "}
                    Applied!
                    <span
                      onClick={() => handleCancelDiscount()}
                      className="text-danger ml-3"
                      style={{ cursor: "pointer" }}
                    >
                      REMOVE
                    </span>
                  </h4>
                </div>
                <div className="col-12 col-lg-4 dropdown-divider"></div>
                <div className="row my-3">
                  <h3 className="col-6 col-lg-3 font-weight-bold">
                    Final Payable Amount
                  </h3>
                  <h3 className="col-6 col-lg-2 mt-0 mb-0">
                    {userData.currencySymbol} {userData.finalAmount}
                  </h3>
                </div>
              </div>
            )}
          </Container>
          {/* <h3 className="font-weight-bold mt-3">
            Total Amount :
            <span className="text-muted">
              {" "}
              {userData.currencySymbol} {userData.amount}
            </span>
          </h3> */}
          {!discountCoupon.status && (
            <Form>
              <Form.Row className="align-items-center">
                <Col xs={10} sm={6} md={6} className="my-1">
                  <Form.Control
                    id="inlineFormInputName"
                    placeholder="Enter discount code (if any)"
                    onChange={(e) =>
                      setDiscountCoupon({
                        ...discountCoupon,
                        error: "",
                        coupon: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={2}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleDiscountCode();
                    }}
                  >
                    {discountCoupon.loader ? (
                      <BeatLoader
                        css={beatLoaderStyling}
                        color="blue"
                        size={15}
                        loading={discountCoupon.loader}
                      />
                    ) : (
                      "Apply"
                    )}
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          )}
          <div>
            {discountCoupon.error.length > 0 && (
              <div className="text-danger font-weight-bold">{discountCoupon.error}</div>
            )}
          </div>
          <h3 className="font-weight-bold mt-5">
            Final Payable Amount:
            <span className="text-muted">
              {" "}
              {userData.currencySymbol} {userData.finalAmount}
            </span>
          </h3>
          <Button
            variant="light"
            className="font-weight-bold my-3"
            style={{
              backgroundColor: "#FF712D",
              color: "white",
              width: width < 600 ? "100%" : "50%",
            }}
            onClick={() => handlePayment()}
          >
            {submitLoader ? (
              <BeatLoader
                css={beatLoaderStyling}
                color="yellow"
                size={15}
                loading={submitLoader}
              />
            ) : (
              "PAY"
            )}
          </Button>
        </Container>
      )}
    </div>
  );
};

export default NewUser;
