import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { Button, Spinner } from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../windowDimensions";
import PrimaryLogo from "../../assets/images/Primary_Logo.png";
import { BeatLoader } from "react-spinners";
import beatLoaderStyling from "../../shared-styling/beatLoaderStyling";

import "./SelectDesiredBatch.scss";
import Axios from "axios";

const jwt = require("jsonwebtoken");

const BASE_URL = process.env.REACT_APP_ENROLL_PAYMENT_URL;
const ENCYPTION_KEY = process.env.REACT_APP_ENCYPTION_KEY;

const SelectDesiredBatch = () => {
  const [userData, setUserData] = useState({
    studentId: "",
    priceId: "",
    avilableBatches: [],
    selectedBatchId: "",
    orderId: "",
    courseId: "",
    newUser: false,
    courseName: "",
    parentName: "",
    amount: "",
    currencySymbol: "",
  });
  const [tokenAmountStatus, setTokenAmountStatus] = useState(false);
  const [timeOut, setTimeOut] = useState(false);
  const [counter, setCounter] = useState(503);
  const [paymentStatusButtonLoader, setPaymentStatusButtonLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const history = useHistory();
  const { width } = useWindowDimensions();

  const format = (time) => {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ":" + seconds;
  };

  //  * * Timer Functionality, reduces by 1000ms.
  //  * * Hits the check Payment API after every 6000ms.
  // * * Timer is not reduced if the Token Amount is paid.
  const counterFunctionality = () => {
    if (tokenAmountStatus === false) {
      counter > 0 ? setTimeout(() => setCounter(counter - 1), 1000) : setTimeOut(true);
      if (counter % 6 === 0) {
        Axios.post(`${BASE_URL}/checkPayment`, {
          orderId: userData.orderId,
        }).then((res) => {
          if (res.data.tokenAmountStatus) {
            setTokenAmountStatus(true);
          }
        });
      }
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("enrollpaymentUser");
    let decoded = jwt.verify(data, ENCYPTION_KEY);

    setUserData({
      ...userData,
      studentId: decoded.studentId,
      priceId: decoded.priceId,
      orderId: decoded.orderId,
      paymentLink: decoded.paymentLink,
      newUser: decoded.newUser,
      courseName: decoded.courseName,
      amount: decoded.amount,
      parentName: decoded.parentName,
      currencySymbol: decoded.currencySymbol,
    });
  }, []);

  // * * If Token Amount is paid (true) , useEffect is called
  // * * Which calls the get/desiredBatches API
  useEffect(() => {
    if (tokenAmountStatus === true) {
      Axios.post(`${BASE_URL}/get/desriedBatches`, {
        priceId: userData.priceId,
        orderId: userData.orderId,
      }).then((res) => {
        setUserData({
          ...userData,
          avilableBatches: res.data.batchList,
          courseId: res.data.courseId,
        });
      });
    }
  }, [tokenAmountStatus]);

  // * * Loop to reduce Timer , stops only when Token Amount paid (true).
  useEffect(() => {
    counterFunctionality();

    // after 5 seconds stop
  }, [counter]);

  const submitBatch = () => {
    setLoading(true);
    if (userData.selectedBatchId.length > 0) {
      Axios.post(`${BASE_URL}/set/desriedBatches`, {
        orderId: userData.orderId,
        batchId: userData.selectedBatchId,
        courseId: userData.courseId,
      }).then((res) => {
        if (res.data.batchBooked) {
          if (userData.newUser) {
            setLoading(false);
            history.push("/add-student");
          } else {
            setLoading(false);
            history.push("/success-payment");
          }
        } else {
          setLoading(false);
          // Set error
        }
      });
    } else {
      if (userData.newUser) {
        setLoading(false);
        history.push("/add-student");
      } else {
        setLoading(false);
        history.push("/success-payment");
      }
    }
  };

  const checkPaymentStatus = () => {
    Axios.post(`${BASE_URL}/checkPayment`, {
      orderId: userData.orderId,
    }).then((res) => {
      if (res.data.tokenAmountStatus) {
        setPaymentStatusButtonLoader(false);
        setTokenAmountStatus(true);
      } else {
        setPaymentStatusButtonLoader(false);
        setError("Payment Status: Not paid");
      }
    });
  };

  const retrieveBatchDays = (timings) => {
    let days = "";
    for (let key in timings) {
      if (timings[key].from.length > 0) {
        console.log("days: ", days);
        days = days + " " + convertDay[key];
      }
    }
    return days;
  };

  const convertDay = {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursay",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",
  };

  return (
    <div>
      {tokenAmountStatus ? (
        <div className="text-center mx-auto">
          <Icon name="check circle" color="green" className="mt-5" size="huge" />
          <div className="mx-auto">
            <div className="paddingContent">
              <h2>Congratulations {userData.parentName}!</h2>
              <h4 style={{ maxWidth: "800px" }} className="text-center mx-auto">
                Your {!userData.newUser && "token amount"} payment of the sum{" "}
                <span className="text-muted">
                  {userData.currencySymbol} {userData.amount}{" "}
                </span>
                and course <span className="text-muted">{userData.courseName}</span> has
                been successful. Your batch & class details will be shared on your
                registered email shortly.
                {userData.avilableBatches.length > 0 &&
                  `If you have a batch preference, please let us
                know below.`}
                {!userData.avilableBatches.length > 0 &&
                  !userData.newUser &&
                  "Please Continue for Registraion"}
              </h4>
              <div style={{ maxWidth: "800px" }} className="text-center mx-auto">
                <div className="row">
                  {userData.avilableBatches.map((batch) => {
                    return (
                      <Card
                        className={
                          userData.selectedBatchId === batch.batchId
                            ? " bg-dark text-white col-12 col-md-5 my-2 mx-auto "
                            : " bg-white text-dark col-12 col-md-5 my-2 mx-auto overlay"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setUserData({ ...userData, selectedBatchId: batch.batchId })
                        }
                      >
                        <Card.Body>
                          <Card.Title>
                            <h3>
                              Starts from{" "}
                              {batch.startingDate.split("-").reverse().join("-")}
                            </h3>
                          </Card.Title>
                          <Card.Subtitle>
                            <h4>{retrieveBatchDays(JSON.parse(batch.timings))}</h4>
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </div>
              </div>
              <Button
                variant="light"
                className="font-weight-bold my-3"
                onClick={() => {}}
                style={{
                  backgroundColor: "#FF712D",
                  color: "white",
                  width: width < 600 ? "100%" : "50%",
                }}
                onClick={() => {
                  submitBatch();
                }}
              >
                {loading ? (
                  <BeatLoader
                    css={beatLoaderStyling}
                    color="yellow"
                    size={15}
                    loading={loading}
                  />
                ) : (
                  <span>
                    {userData.avilableBatches.length > 0 ? "Submit" : "Continue"}
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
      ) : timeOut ? (
        <div className="w-100 h-100">
          <div className="mx-auto my-auto text-center">
            {
              <div>
                <Icon name="warning sign" color="yellow" className="mt-5" size="huge" />
                <h3 style={{ maxWidth: "500px" }} className="text-center mx-auto">
                  We're sorry to inform you that the payment time window for the course
                  you've selected for enrollment has expired.
                </h3>
              </div>
            }
            <Button
              variant="light"
              className="font-weight-bold my-3"
              style={{
                backgroundColor: "#FF712D",
                color: "white",
              }}
              onClick={() => {
                setError("");
                setPaymentStatusButtonLoader(true);
                checkPaymentStatus();
              }}
            >
              {" "}
              {paymentStatusButtonLoader ? (
                <span>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                  Checking...
                </span>
              ) : (
                "CHECK PAYMENT STATUS"
              )}
            </Button>
            <a target="_blank" href={userData.paymentLink}>
              <Button
                variant="light"
                className="font-weight-bold my-3"
                style={{
                  backgroundColor: "#FF712D",
                  color: "white",
                }}
                onClick={() => {
                  setCounter(503);
                  setTimeOut(false);
                }}
              >
                PAY REGISTRATION FEE
              </Button>
            </a>
            {error.length > 0 && <div className="text-danger">{error}</div>}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "fixed" /* or absolute */,
            top: "50%",
          }}
          className="w-100"
        >
          <div className="mx-auto text-center">
            <Spinner animation="grow" variant="warning" className="mx-1" />
            <Spinner animation="grow" variant="warning" className="mx-1" />
            <Spinner animation="grow" variant="warning" className="mx-1" />
            <Spinner animation="grow" variant="warning" className="mx-1" />
            <h3>
              Please complete the payment{" "}
              <span className="font-weight-bold text-muted">{format(counter)}</span>
            </h3>
            <img
              src={PrimaryLogo}
              alt="Primary_Logo"
              width="150"
              height="50"
              className="d-inline-block align-top"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectDesiredBatch;
