import React, { useState, useEffect } from "react";
import { Button, Container, Col } from "react-bootstrap";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Loader from "../Loader/Loader";

import useWindowDimensions from "../../windowDimensions";
const jwt = require("jsonwebtoken");

const BASE_URL = process.env.REACT_APP_ENROLL_PAYMENT_URL;
const ENCYPTION_KEY = process.env.REACT_APP_ENCYPTION_KEY;

const RegisteredUser = () => {
  const [userData, setUserData] = useState({
    parentName: "",
    courseName: "",
    currencySymbol: "",
    studentName: "",
    actualPrice: "",
    discountAmount: "",
    sellingPrice: "",
    installements: [],
    token: {},
  });
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { width } = useWindowDimensions();

  useEffect(() => {
    let data = localStorage.getItem("enrollpaymentUser");
    let decoded = jwt.verify(data, ENCYPTION_KEY);
    Axios.post(`${BASE_URL}/get/paymentDetails/`, {
      studentId: decoded.studentId,
      priceId: decoded.priceId,
    }).then((res) => {
      let userDetails = res.data.paymentDetailsList.rows[0];
      const actualPrice = userDetails.actualPrice;
      const discountAmount = userDetails.discountAmount;
      const sellingPrice = userDetails.sellingPrice;
      const token = userDetails.Payments.filter((payment) => {
        return payment.type === "Token";
      });
      const installements = userDetails.Payments.filter((payment) => {
        return payment.type === "Installment";
      });
      const parentName = res.data.parentName;
      const studentName = res.data.studentName;
      const currencySymbol = res.data.currencySymbol;
      const courseName = res.data.courseName;
      const encryptedObj = jwt.sign(
        {
          ...decoded,
          orderId: token[0].orderId,
          paymentLink: token[0].paymentLink,
          newUser: false,
          courseName: res.data.courseName,
          amount: token[0].amount,
          currencySymbol,
          parentName,
        },
        ENCYPTION_KEY,
      );
      localStorage.setItem("enrollpaymentUser", encryptedObj);
      setUserData({
        ...userData,
        actualPrice,
        discountAmount,
        sellingPrice,
        token: token[0],
        installements,
        parentName,
        studentName,
        currencySymbol,
        courseName,
      });
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <h2 className="my-2">Hello {userData.parentName} ,</h2>
          <h4>
            Here are the payment details for your selected course{" "}
            <span className="font-weight-bold text-muted">{userData.courseName}</span>.
            Kindly confirm & proceed to pay the token amount.
          </h4>
          <Container>
            <div className="row my-3">
              <h3 className="col-6 col-lg-3 font-weight-bold mb-0">Total Amount:</h3>
              <h3 className="col-6 col-lg-2 mb-0 mt-0">
                {" "}
                {userData.currencySymbol} {userData.actualPrice}
              </h3>
            </div>
            <div className="row my-3">
              <h3 className="col-6 col-lg-3 font-weight-bold mb-0">Discount:</h3>
              <h3 className="col-6 col-lg-2 mb-0 mt-0">
                - {userData.currencySymbol} {userData.discountAmount}
              </h3>
            </div>
            <div className="col-12 col-lg-4 dropdown-divider"></div>
            <div className="row my-3">
              <h3 className="col-6 col-lg-3 font-weight-bold">Final Payable Amount</h3>
              <h3 className="col-6 col-lg-2 mt-0 mb-0">
                {userData.currencySymbol} {userData.sellingPrice}
              </h3>
            </div>
          </Container>
          {userData.installements.length > 0 && (
            <Container className="mt-5">
              <h3 className="my-3">Installment Schedule</h3>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Installment</th>
                    <th scope="col">Amount</th>
                    <th scope="col" className="text-center">
                      Due Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userData.installements.map((installment, index) => {
                    return (
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          {userData.currencySymbol} {installment.amount}
                        </td>
                        <td>{dayjs(installment.deadlineDate).format("DD-MM-YYYY")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Container>
          )}
          <h3 className="font-weight-bold mt-0 mb-0">
            Registration Amount (to be paid):
            <span className="text-muted">
              {" "}
              {userData.currencySymbol} {userData?.token?.amount}
            </span>
          </h3>
          <a target="_blank" href={userData.token.paymentLink}>
            <Button
              variant="light"
              className="font-weight-bold my-3"
              style={{
                backgroundColor: "#FF712D",
                color: "white",
                width: width < 600 ? "100%" : "50%",
              }}
              onClick={() => history.push("/desired-batch")}
              type="submit"
            >
              PAY REGISTRATION AMOUNT
            </Button>
          </a>
        </Container>
      )}
    </div>
  );
};

export default RegisteredUser;
