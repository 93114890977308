import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Icon } from "semantic-ui-react";

import "./SuccessPayment.scss";

const CancelPayment = (props) => {
  return (
    <div className="text-center mx-auto">
      <Icon name="warning sign" color="yellow" className="mt-5" size="huge" />
      <div className="mx-auto">
        <div className="paddingContent">
          {/* <h1>Congratulation, {user.parentName}</h1> */}
          <div>Hello @username!</div>
          <div style={{ maxWidth: "800px" }} className="text-center mx-auto">
            We're sorry to inform you that the payment time window for the course you've
            selected for enrollment has expired. Kindly click the button below to proceed
            as a new user.
          </div>
          <div>{/* Time Slot : {user.startTime} - {user.endTime} */}</div>
          {/* <div>Dates : {user.dateBySlot}</div> */}
          <Button
            variant="light"
            className="font-weight-bold my-3"
            style={{
              backgroundColor: "#FF712D",
              color: "white",
              // width: width < 600 ? "100%" : "50%",
            }}
            type="submit"
          >
            PROCEED AS NEW USER<span class="oi oi-check"></span>
          </Button>
          <div>Spread the word! Invite friends & family by sharing this link:</div>
          <a target="_blank" href="https://www.codeyoung.com">
            www.codeyoung.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default CancelPayment;
