import {createReducer} from "@reduxjs/toolkit"

const initialState = {
    data: [],
    isFetched: false
}

export const exampleReducer = createReducer(initialState, {
    INSERT_LOGS: (state, action) => {
        state.data = action.data
    }
})