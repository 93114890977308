import React from "react";
import auth from "../utils/auth";

const Authentication = (props) => {
  let component;
  const { children } = props;

  if (!auth()) {
    window.location.href = "https://www.codeyoung.com/";
  } else {
    component = children;
  }

  // component = children
  return <>{component}</>;
};

export default Authentication;
