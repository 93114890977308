import React, { Component, Fragment, Suspense } from "react";
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";

//Component Imports
import Header from "./Components/Header/Header";
import RegisteredUser from "./Components/RegistredUser/RegisteredUser";
import SuccessPayment from "./Components/StatusPages/SuccessPayment";
import SuccessPaid from "./Components/StatusPages/SuccessPaid";
import CancelPayment from "./Components/StatusPages/CancelPayment";
import Authentication from "./Core/Authentication";
import Routes from "./Core/Routes";
import SelectDesiredBatch from "./Components/SelectDesiredBatch/SelectDesiredBatch";
import NewUser from "./Components/NewUser/NewUser";
import Loader from "./Components/Loader/Loader";

//Lazy Imports
const Login = React.lazy(() => import("./Components/Login/Login"));
const AddStudent = React.lazy(() => import("./Components/AddAStudent/AddStudent"));

const ProtectedRoute = () => {
  const routes = [
    {
      path: "/add-student",
      component: AddStudent,
      exact: true,
    },
    {
      path: "/registered-user",
      component: RegisteredUser,
      exact: true,
    },
    { path: "/desired-batch", component: SelectDesiredBatch, exact: true },
    {
      path: "/success-payment",
      component: SuccessPayment,
      exact: true,
    },
    {
      path: "/success-paid",
      component: SuccessPaid,
      exact: true,
    },
    {
      path: "/cancel-payment",
      component: CancelPayment,
      exact: true,
    },
    {
      path: "/new-user",
      component: NewUser,
      exact: true,
    },
  ];
  return (
    <>
      {/* <Route>   */}
      <Suspense fallback={<Loader />}>
        <Routes routes={routes} redirectTo="/" />
      </Suspense>
    </>
  );
};

class App extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/login/:Id" component={Login} exact />

              <Route path="/" component={Login} exact />
              <Authentication>
                <ProtectedRoute />
              </Authentication>
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </Fragment>
    );
  }
}

export default App;
